<template>
    <va-card class="flex xs12" title="Generate Token">
        <div style="overflow-y: hidden;">
            <va-button style="margin: 0; margin-bottom: 10px;" type="submit" @click="generateToken(true)">Generate Token</va-button>
            <va-card :color="getRowColor(1)" style="margin-right: 25px;">
                <va-card-actions style="color: #34495e;" class="row flex xs12">
                    <div align="center" class="flex xs1">S.No</div>
                    <div class="flex xs11">Token</div>
                </va-card-actions>
            </va-card>
            <div class="flex xs12" style="max-height: 62vh; overflow-y: auto; padding-right: 10px;">
                <div class="flex md12" v-for="(token, index) in tokens">
                    <va-card :color="getRowColor(index)" outlined>
                        <va-card-actions style="color: #34495e;" class="row flex xs12">
                            <div align="center" class="flex xs1">{{token.id}}</div>
                            <div class="flex xs11">{{token.access_token}}</div>
                        </va-card-actions>
                    </va-card>
                </div>
            </div>
        </div>
    </va-card>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import config from '../../i18n/en.json'

Vue.use(vueResource)

export default {
    name: 'token',
    data() {
        return {
            tokens: []
        }
    },
    beforeCreate (){
        
    },
    beforeMount (){
        this.generateToken(false);
    },
    computed: {

    },
    methods: {
        generateToken(generate) {
            this.$http.get(`${config.menu.host}auth/access_token/${generate}`)
            .then(response => {
                this.tokens = response.body;
            }).catch(err => {
            });
        },
        getRowColor (index) {
            return index%2 != 0 ? '#F4F8FA' : "#FFFFFF"
        },
    }
}
</script>

<style lang="scss"></style>
